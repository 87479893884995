import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import offerService from './offerService';

const initialState = {
  offers: [],
  loading: false,
  error: null,
};

export const fetchOffers = createAsyncThunk('offers/fetchOffers', async () => {
  const offers = await offerService.getOffers();
  return offers;
});

export const createOffers = createAsyncThunk('offers/createOffers', async (offers) => {
  const createdProduct = await offerService.createOffers(offers);
  return createdProduct;
});

export const deleteOffers = createAsyncThunk('offers/deleteOffers', async (pid) => {
  const createdProduct = await offerService.deleteOffers(pid);
  return createdProduct;
});

const categorySlice = createSlice({
  name: 'offers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOffers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOffers.fulfilled, (state, action) => {
        state.loading = false;
        state.offers = action.payload;
      })
      .addCase(fetchOffers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createOffers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createOffers.fulfilled, (state, action) => {
        state.loading = false;
        state.offers.push(action.payload);
      })
      .addCase(createOffers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteOffers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteOffers.fulfilled, (state, action) => {
        state.loading = false;
        // state.products = [];
        state.offers = state.offers.filter((product) => product.id !== action.payload);
      })
      .addCase(deleteOffers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default categorySlice.reducer;
