import imageCompression from 'browser-image-compression';
import { collection, addDoc, getDoc, onSnapshot, deleteDoc, doc } from 'firebase/firestore';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage'; // Correct import
import { db } from '../../firebase';

const createGallery = async (product) => {
  const { name, media } = product;
  try {
    const compressedImage = await imageCompression(media, {
      maxSizeMB: 1, // Set the desired maximum size of the compressed image
      maxWidthOrHeight: 800, // Set the desired maximum width or height of the compressed image
    });
    const storage = getStorage();
    const storagePath = `images/${media?.name}`;
    const imageRef = storageRef(storage, storagePath);

    // Step 2: Upload the compressed image instead of the original one

    await uploadBytes(imageRef, media.size && media.size > 1000000 ? compressedImage : media);
    // Step 2: Get the download URL of the uploaded media
    const imageUrl = await getDownloadURL(imageRef);
    const products = {
      name,
      media: imageUrl,
      status: true,
    };

    const response = await addDoc(collection(db, 'gallery'), products);
    console.log('response firebase', response);
    const createdProduct = {
      id: response.id,
      name,
      media: imageUrl,
      status: true,
    };
    return createdProduct;
    // return { id: response.id, data: products };
  } catch (error) {
    console.error('Error creating Testimonials:', error);
    return Promise.reject(error);
  }
};

const getGallery = async (product) => {
  return new Promise((resolve, reject) => {
    const unsubscribe = onSnapshot(
      collection(db, 'gallery'),
      (snapshot) => {
        const todos = [];
        snapshot.forEach((doc) => {
          todos.push({ id: doc.id, ...doc.data() });
        });
        resolve(todos);
      },
      reject
    );
  });
};

const deleteGallery = async (productId) => {
  try {
    // Step 1: Delete the product document from Firestore
    const response = await deleteDoc(doc(db, 'gallery', productId));
    return { productId };
  } catch (error) {
    // Handle any errors that may occur during deletion
    console.error('Error deleting Testimonial:', error);
    throw error;
  }
};

const galleryService = {
  createGallery,
  getGallery,
  deleteGallery,
};
export default galleryService;
