// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Gallery',
    path: '/dashboard/gallery',
    icon: icon('ic_cart'),
  },
  {
    title: 'Testimonials',
    path: '/dashboard/testimonial',
    icon: icon('ic_blog'),
  },
  {
    title: 'product',
    path: '/dashboard/products',
    icon: icon('ic_cart'),
  },
  {
    title: 'offers',
    path: '/dashboard/offers',
    icon: icon('ic_disabled'),
  },
];

export default navConfig;
