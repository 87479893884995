import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import galleryService from './galleryServices';

const initialState = {
  gallery: [],
  loading: false,
  error: null,
};

export const fetchGallery = createAsyncThunk('gallery/fetchGallery', async () => {
  const gallery = await galleryService.getGallery();
  return gallery;
});

export const createGallery = createAsyncThunk('gallery/createGallery', async (gallery) => {
  const createdProduct = await galleryService.createGallery(gallery);
  return createdProduct;
});

export const deleteGallery = createAsyncThunk('gallery/deleteGallery', async (pid) => {
  const createdProduct = await galleryService.deleteGallery(pid);
  return createdProduct;
});

const gallerySlice = createSlice({
  name: 'gallery',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchGallery.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchGallery.fulfilled, (state, action) => {
        state.loading = false;
        state.gallery = action.payload;
      })
      .addCase(fetchGallery.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createGallery.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createGallery.fulfilled, (state, action) => {
        state.loading = false;
        state.gallery.push(action.payload);
      })
      .addCase(createGallery.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteGallery.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteGallery.fulfilled, (state, action) => {
        state.loading = false;
        // state.products = [];
        state.gallery = state.gallery.filter((product) => product.id !== action.payload);
      })
      .addCase(deleteGallery.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default gallerySlice.reducer;
