import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';

// ----------------------------------------------------------------------

export default function LoginForm(props) {
  const { onSubmit, onChange } = props;
  const [showPassword, setShowPassword] = useState(false);
  const { email, password, emailErro, passwordError } = props.formData;

  return (
    <form onSubmit={onSubmit}>
      <Stack spacing={3}>
        <TextField error={!email && emailErro} name="email" label="Email address" onChange={onChange} />

        <TextField
          error={!password && passwordError}
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          onChange={onChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  {/* <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} /> */}
                </IconButton>
              </InputAdornment>
            ),
          }}
          // helperText="password"
        />
      </Stack>

      {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Checkbox name="remember" onClick={() => setShowPassword(!showPassword)} label="Remember me" />
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link>
      </Stack> */}

      <LoadingButton fullWidth size="large" type="submit" variant="contained" sx={{ my: 2 }}>
        Login
      </LoadingButton>
    </form>
  );
}
