import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
// import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import CategoryPage from './pages/CategoryPages';
import DashboardAppPage from './pages/DashboardAppPage';
import GalleryPage from './pages/SliderPages';
import OfferPage from './pages/OfferPage';

// ----------------------------------------------------------------------

export default function Router() {
  const user = localStorage.getItem('user');
  let routes;
  if (user) {
    routes = [
      {
        path: '/dashboard',
        element: <DashboardLayout />,
        children: [
          { element: <Navigate to="/dashboard/app" />, index: true },
          { path: 'app', element: <DashboardAppPage /> },
          { path: 'products', element: <ProductsPage /> },
          { path: 'testimonial', element: <CategoryPage /> },
          { path: 'gallery', element: <GalleryPage /> },
          { path: 'offers', element: <OfferPage /> },
        ],
      },
      {
        path: 'login',
        element: <LoginPage />,
      },
      {
        element: <SimpleLayout />,
        children: [
          { element: <Navigate to="/dashboard/app" />, index: true },
          { path: '404', element: <Page404 /> },
          { path: '*', element: <Navigate to="/404" /> },
        ],
      },
      {
        path: '*',
        element: <Navigate to="/404" replace />,
      },
    ];
  } else {
    // Define routes for non-authenticated user
    console.log('Non');
    routes = [
      {
        path: 'login',
        element: <LoginPage />,
      },
      {
        path: '*',
        element: <Navigate to="/login" />,
      },
    ];
  }

  return useRoutes(routes);
}
