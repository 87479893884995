import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
import { Link } from 'react-router-dom';
import { collection, onSnapshot, getDocs } from 'firebase/firestore';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
// components
import Iconify from '../components/iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
// ----------------------------------------------------------------------
import { db } from '../firebase';

export default function DashboardAppPage() {
  const [testCount, setTestCount] = useState(0);
  const [galleryCount, setGalleryCount] = useState(0);
  const [offerCount, setOfferCount] = useState(0);
  const [productCount, setProductCount] = useState(0);

  const getTestimonialCount = async () => {
    try {
      const queryTesti = await getDocs(collection(db, 'testimonial'));
      const queryGallery = await getDocs(collection(db, 'gallery'));
      const queryProduct = await getDocs(collection(db, 'products'));
      const queryOffers = await getDocs(collection(db, 'offers'));

      setTestCount(queryTesti.size);
      setGalleryCount(queryGallery.size);
      setProductCount(queryProduct.size);
      setOfferCount(queryOffers.size);

      return 1;
    } catch (error) {
      console.error('Error fetching testimonial count:', error);
      return 0;
    }
  };

  getTestimonialCount();

  const theme = useTheme();

  return (
    <>
      <Helmet>
        <title> Dashboard | Hifix </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <Link to="/dashboard/gallery" style={{ textDecoration: 'none' }}>
              <AppWidgetSummary title="Gallery" total={galleryCount} icon={'solar:gallery-broken'} />
            </Link>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Link to="/dashboard/testimonial" style={{ textDecoration: 'none' }}>
              <AppWidgetSummary title="Testimonials" total={testCount} color="info" icon={'codicon:feedback'} />
            </Link>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Link to="/dashboard/products" style={{ textDecoration: 'none' }}>
              <AppWidgetSummary title="Products" total={productCount} color="warning" icon={'mdi:cart-outline'} />
            </Link>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Link to="/dashboard/offers" style={{ textDecoration: 'none' }}>
              <AppWidgetSummary title="Offers" total={offerCount} color="error" icon={'ic:outline-local-offer'} />
            </Link>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
