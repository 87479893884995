import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export const API_REGISTER = 'users';
export const API_LOGIN = 'users/login';
export const API_PRODUCTS = 'products';
export const API_CATEGORY = 'category';
export const API_MEDIA = 'media';
export const API_MEDIA_FETCH = 'media/fetch/';
export const API_STUDENTS = 'students';
export const API_SLIDER = 'slider';

export default api;
