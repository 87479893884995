import { configureStore } from '@reduxjs/toolkit';
import snackBarReducer from '../features/Snackbar/snackbar.slice';
import productReducer from '../sections/@dashboard/products/productsSlice';
import categoryReducer from '../features/Category/category.slices';
import galleryReducer from '../features/Gallery/gallery.slices';
import offersReducer from '../features/Offers/offer.slices';

export const store = configureStore({
  reducer: {
    snackbar: snackBarReducer,
    products: productReducer,
    testimonials: categoryReducer,
    gallery: galleryReducer,
    offers: offersReducer,
  },
});
