import PropTypes from 'prop-types';
// @mui
import { Grid } from '@mui/material';
import ShopProductCard from './ProductCard';

// ----------------------------------------------------------------------

ProductList.propTypes = {
  products: PropTypes.array.isRequired,
};
export default function ProductList({ products, handleDeleteClick, ...other }) {
  console.log('prro', products);
  return (
    <Grid container spacing={3} {...other}>
      {products.map((product) => (
        <Grid key={product.id} item xs={12} sm={6} md={3}>
          <ShopProductCard product={product} handleDeleteClick={handleDeleteClick} />
        </Grid>
      ))}
    </Grid>
  );
}

// import { useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { fetchProducts } from './productsSlice';

// const ProductList = () => {
//   const dispatch = useDispatch();
//   const products = useSelector((state) => state.products.products);
//   const loading = useSelector((state) => state.products.loading);
//   const error = useSelector((state) => state.products.error);

//   useEffect(() => {
//     dispatch(fetchProducts());
//   }, [dispatch]);

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   if (error) {
//     return <div>Error: {error}</div>;
//   }

//   return (
//     <div>
//       {products.map((product) => (
//         <div key={product.id}>{product.name}</div>
//       ))}
//     </div>
//   );
// };

// export default ProductList;
