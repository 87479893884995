import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button } from '@mui/material';
// hooks
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/logo';
import Iconify from '../components/iconify';
// sections
import { LoginForm } from '../sections/auth/login';
import { showSnackbar } from '../features/Snackbar/snackbar.slice';
import { auth } from '../firebase';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const mdUp = useResponsive('up', 'md');
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: '',
    password: '',
    emailErro: false,
    passwordError: false,
  });

  const { email, password } = formData;
  const dispatch = useDispatch();

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const userData = {
      email,
      password,
    };
    if (!email && !password) {
      setFormData((prevState) => ({
        ...prevState,
        emailErro: true,
        passwordError: true,
      }));
      dispatch(showSnackbar({ message: 'Email And Password Required ', status: 'error' }));
      console.log('snackbar');
    } else if (!email) {
      setFormData((prevState) => ({
        ...prevState,
        emailErro: true,
      }));
    } else if (!password) {
      setFormData((prevState) => ({
        ...prevState,
        passwordError: true,
      }));
    } else {
      handleLogin(email, password);
      // dispatch(login(userData));
    }
  };

  const handleLogin = (email, password) => {
    signInWithEmailAndPassword(auth, email, password)
      .then((cred) => {
        localStorage.setItem('user', cred?.user?.accessToken);
        dispatch(showSnackbar({ message: 'Login Successfully', status: 'success' }));
        navigate('/dashboard/app');
      })
      .catch((error) => {
        // localStorage.setItem('logged_in', false);
        dispatch(showSnackbar({ message: 'Email Or Password Error ', status: 'error' }));
      });
  };

  return (
    <>
      <Helmet>
        <title> Login | Minimal UI </title>
      </Helmet>

      <StyledRoot>
        {mdUp && (
          <StyledSection>
            <img src="/assets/images/avatars/logos.png" alt="login" />
          </StyledSection>
        )}

        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h4" gutterBottom>
              Sign in to Hifix Interio
            </Typography>

            {/* <Typography variant="body2" sx={{ mb: 5 }}>
              Don’t have an account? {''}
              <Link
                variant="subtitle2"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate('/register');
                }}
              >
                Get started
              </Link>
            </Typography> */}

            {/* <Stack direction="row" spacing={2}>
              <Button fullWidth size="large" color="inherit" variant="outlined">
                <Iconify icon="eva:google-fill" color="#DF3E30" width={22} height={22} />
              </Button>

              <Button fullWidth size="large" color="inherit" variant="outlined">
                <Iconify icon="eva:facebook-fill" color="#1877F2" width={22} height={22} />
              </Button>

              <Button fullWidth size="large" color="inherit" variant="outlined">
                <Iconify icon="eva:twitter-fill" color="#1C9CEA" width={22} height={22} />
              </Button>
            </Stack> */}

            <Divider sx={{ my: 3 }}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                LOGIN
              </Typography>
            </Divider>

            <LoginForm onSubmit={onSubmit} onChange={onChange} formData={formData} />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
