import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// @mui
import { Container, Stack, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
// components
import { ProductSort, ProductList, ProductCartWidget, ProductFilterSidebar } from '../sections/@dashboard/products';

import { createOffers, fetchOffers, deleteOffers } from '../features/Offers/offer.slices';
// import { fetchCategory } from '../features/Category/category.slices';
import { CreateOfferModal } from '../sections/@dashboard/products/CreateOfferModel';
import { showSnackbar } from '../features/Snackbar/snackbar.slice';
import Iconify from '../components/iconify';
// ----------------------------------------------------------------------
export default function OfferPage() {
  const [openFilter, setOpenFilter] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const dispatch = useDispatch();
  const products = useSelector((state) => state.offers.offers);
  const loading = useSelector((state) => state.offers.loading);
  const error = useSelector((state) => state.offers.error);

  const [productData, setProductData] = useState({
    name: '',
    desc: '',
    media: '',
    price: '',
    offer: '',
  });
  const [selectedImage, setSelectedImage] = useState(null);

  const handleDeleteClick = async (id) => {
    try {
      console.log('deleted successfully', id);
      await dispatch(deleteOffers(id));
      // Nihad any options
      dispatch(fetchOffers());
      dispatch(showSnackbar({ message: 'Product Deleted ', status: 'success' }));
      // Handle success, show a success message, update the state, etc.
      console.log('Product deleted successfully');
    } catch (error) {
      // Handle errors, show an error message, etc.
      dispatch(showSnackbar({ message: 'Product Error ', status: 'error' }));
      console.error('Error deleting product:', error);
    }
  };

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  useEffect(() => {
    dispatch(fetchOffers());
    // dispatch(fetchCategory());
  }, [dispatch]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(productData);
    handleCloseDialog();
  };

  const handleChange = (e) => {
    console.log('datas', e.target.value);
    setProductData({
      ...productData,
      [e.target.name]: e.target.value,
    });
    console.log('field', productData);
  };

  const handleImageChange = (e) => {
    console.log('file', e);
    setProductData({
      ...productData,
      media: e.target.files[0],
    });
    const imageUrl = URL.createObjectURL(e.target.files[0]);
    setSelectedImage(imageUrl);
  };

  const handleCreateProduct = async (e) => {
    e.preventDefault();
    console.log('datas', productData);
    if (productData.name !== '' && productData.media !== '') {
      dispatch(createOffers(productData))
        .then(() => {
          dispatch(showSnackbar({ message: 'Offers Successfully Added ', status: 'success' }));
          setProductData({
            name: '',
            desc: '',
            media: '',
            price: '',
            offer: '',
          });
          setSelectedImage(null);
          handleCloseDialog();
        })
        .catch((error) => {
          dispatch(showSnackbar({ message: 'Offers Error', status: 'error' }));
        });
      // console.log('dispatch createOffers');
    } else {
      dispatch(showSnackbar({ message: 'Title And media Required', status: 'error' }));
    }
    // await
  };

  return (
    <>
      <Helmet>
        <title> Dashboard: Offers</title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Package Offers
        </Typography>

        <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenDialog}
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Create
            </Button>
            {/* <ProductFilterSidebar
              openFilter={openFilter}
              onOpenFilter={handleOpenFilter}
              onCloseFilter={handleCloseFilter}
            />
            <ProductSort /> */}
          </Stack>
        </Stack>

        <ProductList products={products} handleDeleteClick={handleDeleteClick} />
        {/* <ProductCartWidget /> */}
      </Container>
      <CreateOfferModal
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        handleSubmit={handleSubmit}
        productData={productData}
        handleChange={handleChange}
        loading={loading}
        handleImageChange={handleImageChange}
        setProductData={setProductData}
        handleCreateProduct={handleCreateProduct}
        selectedImage={selectedImage}
      />
    </>
  );
}
