import React, { useState, useEffect } from 'react';
import MuiAlert from '@mui/material/Alert';
import { Snackbar } from '@mui/material';
import { useSelector } from "react-redux";

export default function SnackBarComponent() {
    const snackData = useSelector((state) => state.snackbar);
    const [open, setOpen] = useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const Alert = React.forwardRef((props, ref) => {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    useEffect(() => {
        console.log("uss")
        if (snackData && snackData.message) {
            setOpen(true);
        }
    }, [snackData]);

    return (
        <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
            <Alert severity={snackData && snackData.status}>{snackData && snackData.message}</Alert>
        </Snackbar>
    );
}
