import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import { useDispatch, useSelector } from 'react-redux';
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
import { fetchCategory, createCategory, deleteCategory } from '../features/Category/category.slices';
// ----------------------------------------------------------------------
import { CreateTestimonialModal } from '../sections/@dashboard/products/CreateTestimonialModel';
import { showSnackbar } from '../features/Snackbar/snackbar.slice';

const TABLE_HEAD = [
  { id: 'img', label: '', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'desc', label: 'Testimonial', alignRight: false },
  { id: 'note', label: 'Role', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function CategoryPage() {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openDialog, setOpenDialog] = useState(false);

  const TESTIMONILIST = useSelector((state) => state.testimonials.category);
  const loading = useSelector((state) => state.testimonials.loading);
  const error = useSelector((state) => state.testimonials.error);
  console.log('Cat list', TESTIMONILIST);

  const [productData, setProductData] = useState({
    name: '',
    description: '',
    media: '',
    role: '',
  });
  const [selectedImage, setSelectedImage] = useState(null);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    dispatch(fetchCategory());
  }, [dispatch]);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(productData);
    handleCloseDialog();
  };

  const handleChange = (e) => {
    console.log('datas', e.target.value);
    setProductData({
      ...productData,
      [e.target.name]: e.target.value,
    });
    console.log('field', productData);
  };

  const handleImageChange = (e) => {
    console.log('file', e);
    setProductData({
      ...productData,
      media: e.target.files[0],
    });
    const imageUrl = URL.createObjectURL(e.target.files[0]);
    setSelectedImage(imageUrl);
  };

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = TESTIMONILIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - TESTIMONILIST.length) : 0;

  const filteredUsers = applySortFilter(TESTIMONILIST, getComparator(order, orderBy), filterName);
  const isNotFound = !filteredUsers.length && !!filterName;

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const handleCreateProduct = async (e) => {
    e.preventDefault();
    console.log('datas', productData);
    if (productData.description !== '') {
      dispatch(createCategory(productData))
        .then(() => {
          dispatch(showSnackbar({ message: 'Testimonial Successfully Added ', status: 'success' }));
          setProductData({
            name: '',
            description: '',
            media: '',
            role: '',
          });
          setSelectedImage(null);
          handleCloseDialog();
        })
        .catch((error) => {
          dispatch(showSnackbar({ message: 'Testimonial Error', status: 'error' }));
        });
      // console.log('dispatch createProduct');
    } else {
      dispatch(showSnackbar({ message: 'Description Required', status: 'error' }));
    }
    // await
  };

  const handleDeleteClick = async (id) => {
    try {
      console.log('deleted successfully', id);
      await dispatch(deleteCategory(id));

      // Nihad any options
      dispatch(fetchCategory());
      dispatch(showSnackbar({ message: 'Testimonial Deleted ', status: 'success' }));
    } catch (error) {
      // Handle errors, show an error message, etc.
      dispatch(showSnackbar({ message: 'Testimonial Error ', status: 'error' }));
      console.error('Error deleting product:', error);
    }
  };

  return (
    <>
      <Helmet>
        <title> Dashboard: Testimonials</title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Testimonial
          </Typography>
          <Button variant="contained" onClick={handleOpenDialog} startIcon={<Iconify icon="eva:plus-fill" />}>
            Testimonial
          </Button>
        </Stack>

        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={TESTIMONILIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, name, status, description, media, role } = row;
                    const selectedUser = selected.indexOf(name) !== -1;
                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, name)} />
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar
                              sx={{
                                borderRadius: 0,
                                width: 70,
                                height: 70,
                              }}
                              alt={name}
                              src={media}
                            />
                            {/* <Typography variant="subtitle2" noWrap>
                              {name}
                            </Typography> */}
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{name}</TableCell>
                        <TableCell align="left">{description}</TableCell>
                        <TableCell align="left">{role}</TableCell>
                        <TableCell align="left">
                          <Label color={status ? 'success' : 'error'}>{status ? 'Enable' : 'Disable'}</Label>
                        </TableCell>
                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={() => handleDeleteClick(id)}>
                            <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={TESTIMONILIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        {/* <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem> */}

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>

      <CreateTestimonialModal
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        handleSubmit={handleSubmit}
        productData={productData}
        handleChange={handleChange}
        handleImageChange={handleImageChange}
        setProductData={setProductData}
        handleCreateProduct={handleCreateProduct}
        selectedImage={selectedImage}
      />
    </>
  );
}
