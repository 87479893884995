import { collection, addDoc, getDoc, onSnapshot, deleteDoc, doc } from 'firebase/firestore';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage'; // Correct import
import { db } from '../../firebase';

const createCategory = async (product) => {
  const { name, media, description, role } = product;
  try {
    const storage = getStorage();
    const storagePath = `images/${media?.name}`;
    const imageRef = storageRef(storage, storagePath);
    await uploadBytes(imageRef, media);
    // Step 2: Get the download URL of the uploaded media
    const imageUrl = await getDownloadURL(imageRef);
    console.log('imageUrl', imageUrl);

    const products = {
      name,
      media: imageUrl,
      description,
      role,
      status: true,
    };

    const response = await addDoc(collection(db, 'testimonial'), products);
    console.log('response firebase', response);
    const createdProduct = {
      id: response.id,
      name,
      media: imageUrl,
      description,
      role,
      status: true,
    };
    return createdProduct;
    // return { id: response.id, data: products };
  } catch (error) {
    console.error('Error creating Testimonials:', error);
    return Promise.reject(error);
  }
};

const getCategory = async (product) => {
  return new Promise((resolve, reject) => {
    const unsubscribe = onSnapshot(
      collection(db, 'testimonial'),
      (snapshot) => {
        const todos = [];
        snapshot.forEach((doc) => {
          todos.push({ id: doc.id, ...doc.data() });
        });
        resolve(todos);
      },
      reject
    );
  });
};

const deleteCategory = async (productId) => {
  try {
    // Step 1: Delete the product document from Firestore
    const response = await deleteDoc(doc(db, 'testimonial', productId));
    return { productId };
  } catch (error) {
    // Handle any errors that may occur during deletion
    console.error('Error deleting Testimonial:', error);
    throw error;
  }
};

const categoryService = {
  createCategory,
  getCategory,
  deleteCategory,
};
export default categoryService;
