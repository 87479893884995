import { collection, addDoc, getDoc, onSnapshot, deleteDoc, doc } from 'firebase/firestore';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage'; // Correct import
import { db } from '../../../firebase';
import api, { API_PRODUCTS } from '../../../utils/axios';

const createProduct = async (product) => {
  const { name, media, description, category } = product;
  console.log('product', product);
  try {
    const storage = getStorage();
    const storagePath = `images/${media.name}`;
    const imageRef = storageRef(storage, storagePath);
    await uploadBytes(imageRef, media);
    // Step 2: Get the download URL of the uploaded media
    const imageUrl = await getDownloadURL(imageRef);
    console.log('imageUrl', imageUrl);

    const products = {
      name,
      media: imageUrl,
      description,
      category,
      status: true,
    };

    const response = await addDoc(collection(db, 'products'), products);
    console.log('response firebase', response);
    const createdProduct = {
      id: response.id,
      name,
      media: imageUrl,
      description,
      category,
      status: true,
    };
    console.error('Product sucsss');
    return createdProduct;
    // return { id: response.id, data: products };
  } catch (error) {
    console.error('Error creating products:', error);
    return Promise.reject(error);
  }
};

const getProducts = async (product) => {
  return new Promise((resolve, reject) => {
    const unsubscribe = onSnapshot(
      collection(db, 'products'),
      (snapshot) => {
        const todos = [];
        snapshot.forEach((doc) => {
          todos.push({ id: doc.id, ...doc.data() });
        });
        resolve(todos);
      },
      reject
    );
  });
};

const deleteProduct = async (productId) => {
  try {
    // Step 1: Delete the product document from Firestore
    const response = await deleteDoc(doc(db, 'products', productId));
    return { productId };
  } catch (error) {
    // Handle any errors that may occur during deletion
    console.error('Error deleting product:', error);
    throw error;
  }
};

const productService = {
  createProduct,
  getProducts,
  deleteProduct,
};
export default productService;
