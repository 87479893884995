import PropTypes from 'prop-types';
import { Box, Card, Link, Typography, Stack, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';

const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

ShopProductCard.propTypes = {
  product: PropTypes.object,
};

export default function ShopProductCard({ product, handleDeleteClick }) {
  const { id, name, media, price, offer } = product;
  console.log('product', product);

  return (
    <Card>
      <Box sx={{ pt: '100%', position: 'relative' }}>
        <StyledProductImg alt={name} src={media} />
      </Box>

      <Stack direction="row" justifyContent="space-between" spacing={2} sx={{ p: 3 }}>
        <Link color="inherit" underline="hover">
          <Typography variant="subtitle2" noWrap mt={1}>
            {name} <br />
            {price ? 'Price :' : ''}
            {price} <br />
            {offer ? 'Offer Price :' : ''}
            {offer}
          </Typography>
        </Link>
        <IconButton color="inherit" onClick={() => handleDeleteClick(id)}>
          <DeleteIcon />
        </IconButton>
      </Stack>
    </Card>
  );
}
