import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import React, { useState } from 'react';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CircularProgress from '@material-ui/core/CircularProgress';

export function CreateGalleryModal(props) {
  const {
    openDialog,
    handleCloseDialog,
    handleSubmit,
    productData,
    handleChange,
    handleImageChange,
    loading,
    setProductData,
    selectedImage,
    handleCreateProduct,
  } = props;
  console.log('loading', loading);

  return (
    <Dialog open={openDialog} onClose={handleCloseDialog} PaperProps={{ style: { width: 500 } }}>
      <DialogTitle>Create Product</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <TextField label="Title" name="name" value={productData?.name} onChange={handleChange} fullWidth />
            <Button variant="outlined" startIcon={<CameraAltIcon />} component="label">
              Image
              <input type="file" id="upload-file" style={{ display: 'none' }} onChange={handleImageChange} />
            </Button>
            {selectedImage && (
              <div>
                <img src={selectedImage} alt="Selected" style={{ maxWidth: '200px', maxHeight: '200px' }} />
              </div>
            )}
          </Stack>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="primary">
          Cancel
        </Button>
        <Button type="submit" form="productForm" color="primary" onClick={handleCreateProduct}>
          {loading ? <CircularProgress size={24} color="inherit" /> : ' Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CreateGalleryModal;
