import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import React, { useState } from 'react';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CircularProgress from '@material-ui/core/CircularProgress';

export function CreateOfferModal(props) {
  const {
    openDialog,
    handleCloseDialog,
    handleSubmit,
    productData,
    handleChange,
    handleImageChange,
    loading,
    setProductData,
    selectedImage,
    handleCreateProduct,
  } = props;
  console.log('loading', loading);

  return (
    <Dialog open={openDialog} onClose={handleCloseDialog} PaperProps={{ style: { width: 500 } }}>
      <DialogTitle>Create Offers</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <TextField label="Product" name="name" value={productData?.name} onChange={handleChange} fullWidth />

            <Stack
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '5px',
              }}
            >
              <TextField label="Price" name="price" value={productData?.price} onChange={handleChange} />
              <TextField label="Offer Price" name="offer" value={productData?.offer} onChange={handleChange}/>
            </Stack>
            <TextField
              label="Notes"
              name="desc"
              value={productData?.description}
              onChange={handleChange}
              fullWidth
            />
            {/* <label htmlFor="media">
                Image
                <input type="file" id="media" name="media" accept="image/*" onChange={handleImageChange} />
              </label> */}
            <Button variant="outlined" startIcon={<CameraAltIcon />} component="label">
              Image
              <input type="file" id="upload-file" style={{ display: 'none' }} onChange={handleImageChange} />
            </Button>
            {selectedImage && (
              <div>
                <img src={selectedImage} alt="Selected" style={{ maxWidth: '150px', maxHeight: '150px' }} />
              </div>
            )}
            {/* <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Category</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // defaultValue="manufacturing"
                value={productData?.category}
                label="Category"
                name="category"
                onChange={handleChange}
              >
                <MenuItem value="manufacturing">Manufacturing</MenuItem>
                <MenuItem value="trading">Trading</MenuItem>
              </Select>
            </FormControl> */}
          </Stack>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="primary">
          Cancel
        </Button>
        <Button type="submit" form="productForm" color="primary" onClick={handleCreateProduct}>
          {loading ? <CircularProgress size={24} color="inherit" /> : ' Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CreateOfferModal;
